import React, { SVGProps } from 'react';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Capa_1"
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 512 512"
      {...props}
    >
      <title>Logo Hablaquí</title>
      <path
        fill="#2070e5"
        d="M255.75,0C114.5,0,0,114.5,0,255.75S114.5,511.5,255.75,511.5,511.5,397,511.5,255.75,397,0,255.75,0ZM290.8,223.5V150.3c0-13.32,9.44-25.22,22.63-27.11a26.58,26.58,0,0,1,30.51,26.28v63.05a284.94,284.94,0,0,1-53.14,13.59Zm-112.44-4.95v-68c0-14.22,10.73-26.7,24.93-27.56a26.58,26.58,0,0,1,28.21,26.52v78.22h0v.15c-2.64,0-5.25,0-7.86-.09a413.37,413.37,0,0,1-45.28-3.24h0Zm191.16,49.34a164.38,164.38,0,0,1-25.58,11.88v98.44H290.8V293.37a404.56,404.56,0,0,1-59.3,5v79.81H178.36V296c-39.78-4.51-73.86-14.54-96.73-28a19.84,19.84,0,0,1-5.43-29.41l.27-.35a19.77,19.77,0,0,1,23.15-6.14c31.78,13.36,76.46,21.68,126,21.68v-.14c49.49,0,94.17-8.32,126-21.68a19.73,19.73,0,0,1,23.08,6.06l.34.43A19.84,19.84,0,0,1,369.52,267.89Z"
      />
    </svg>
  );
}
